import React from 'react';
import { fluidImage } from '../../util/fluidImage';

import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../../components/Layout';

const HausDerAndacht = ({ data }) => (
    <Layout
        site="hda"
        title="Haus der Andacht"
        description="Das kontinentale Haus der Andacht von Europa
        befindet sich in Hofheim-Langenhain im Taunus, in
        der Nähe von Frankfurt."
        image={data.heroImage.childImageSharp.fluid.src}
    >
        <Helmet>
            <title>Haus der Andacht – Bahá’í in Deutschland</title>
            <meta
                property="og:title"
                content="Haus der Andacht – Bahá’í in Deutschland"
            />
        </Helmet>
        <main id="main" role="main">
            <div className="intro-block">
                <Img
                    backgroundColor="#f8f6f4"
                    fluid={data.heroImage.childImageSharp.fluid}
                />
            </div>
            <div className="intro-text make-disappear" id="startread">
                <div className="container">
                    <div className="side">
                        <p>
                            <blockquote>
                                <q>
                                    „Dies ist der Tag, da Gottes erhabenste
                                    Segnungen den Menschen zugeströmt sind, der
                                    Tag, da alles Erschaffene mit Seiner
                                    mächtigsten Gnade erfüllt wurde. Alle Völker
                                    der Welt haben die Pflicht, ihre Gegensätze
                                    auszugleichen und in vollkommener Einheit
                                    und in Frieden im Schatten des Baumes Seiner
                                    Obhut und Gnade zu wohnen.“
                                </q>
                            </blockquote>
                            <cite>Bahá’u’lláh</cite>
                        </p>
                        <p className="sans-serif standard-text">
                            <blockquote>
                                <q>
                                    „O Du gütiger Herr! Du hast die ganze
                                    Menschheit aus dem gleichen Stamm
                                    erschaffen. Du hast bestimmt, dass alle der
                                    gleichen Familie angehören. In Deiner
                                    heiligen Gegenwart sind alle Deine Diener,
                                    die ganze Menschheit findet Schutz in Deinem
                                    Heiligtum. Alle sind um Deinen Gabentisch
                                    versammelt; alle sind erleuchtet vom Lichte
                                    Deiner Vorsehung…“
                                </q>
                            </blockquote>
                            <cite>‘Abdu’l-Bahá</cite>
                        </p>
                        <p className="sans-serif standard-text">
                            <blockquote>
                                <q>
                                    „O Volk der Welt! Bauet Andachtshäuser in
                                    allen Landen im Namen Dessen, Der der Herr
                                    aller Religionen ist. Macht sie so
                                    vollkommen, wie es in der Welt des Seins
                                    möglich ist, und schmückt sie mit dem, was
                                    ihnen gebührt… Sodann feiert darin in Freude
                                    und Heiterkeit den Lobpreis eures Herrn, des
                                    Allbarmherzigen. Wahrlich, Sein Gedenken
                                    erheitert das Auge und füllt das Herz mit
                                    Freude.“
                                </q>
                            </blockquote>
                            <cite>Bahá’u’lláh</cite>
                        </p>
                        <p className="separator dont-hide desktop-hidden" />
                    </div>
                    <div className="text">
                        <p>
                            Die Bahá’í-Häuser der Andacht auf der ganzen Welt
                            sind dem Ziel gewidmet, die Hinwendung zu Gott mit
                            dem Dienst an der Menschheit zu verbinden. Es gibt
                            acht kontinentale Häuser der Andacht, die sich in
                            Australien, Chile, Deutschland, Indien, Panama,
                            Samoa, Uganda und den Vereinigten Staaten befinden.
                            Diese Gebäude stehen allen Menschen offen und werden
                            alljährlich von Millionen Menschen besucht.
                        </p>
                        <p>
                            Jedes Haus der Andacht hat seine eigene
                            unverwechselbare Architektur, die von der heimischen
                            Landschaft und Kultur beeinflusst wird. Bahá'i
                            Häuser der Andacht sind Orte des Gebets, der
                            Meditation und Besinnung. Sie sind zur Lobpreisung
                            Gottes konzipiert, offen für alle, unabhängig von
                            Religion oder Glaubensrichtung, Nationalität oder
                            Hintergrund.
                        </p>

                        <p>
                            <div className="img-holder">
                                <img
                                    src={
                                        data.collage1Image.childImageSharp.fluid
                                            .src
                                    }
                                    alt=""
                                />
                            </div>
                        </p>

                        <p>
                            In den Häuser der Andacht finden einfache
                            Andachtsprogramme statt, bei denen das Wort Gottes
                            aus den Heiligen Schriften der Bahá’í und aus den
                            Schriften anderer Weltreligionen rezitiert, gelesen
                            oder gesungen wird, ohne feste Sakramente, Rituale
                            oder Predigten.
                        </p>
                        <p>
                            Die Häuser der Andacht sind die Aufgangsorte der
                            Erwähnung Gottes und werden auch als Oase für tiefes
                            Nachdenken über die geistige Wirklichkeit und die
                            grundlegenden Fragen des Lebens, einschließlich der
                            persönlichen und gemeinschaftlichen Verantwortung
                            für die Verbesserung der Gesellschaft bezeichnet.
                        </p>
                        <p>
                            Das kontinentale Haus der Andacht von Europa
                            befindet sich in Hofheim-Langenhain im Taunus, in
                            der Nähe von Frankfurt. Die Hinwendung zu Gott, das
                            Nachdenken über und die Inspiration aus dem Wort
                            Gottes, welche in der Andacht verstärkt werden,
                            geben den Anstoß zu einem gemeinsamen Handeln.
                            Dieses ist konstruktiv und auf das Gemeinwohl
                            ausgerichtet. Hierdurch entstehen immer wieder
                            vielfältige Veranstaltungen am Haus der Andacht, die
                            zum Mitmachen einladen: an jedem Sonntag
                            stattfindende Andachten, in denen aus den Heiligen
                            Schriften aller Religionen gelesen wird, diverse
                            Aktivitäten für Kinder, Jugendliche und Erwachsene,
                            das jährlich stattfindende Sommerfest,
                            Chor-Festivals oder verschiedene thematische
                            Veranstaltungen im benachbarten Nationalen Zentrum
                            der Bahá’í-Gemeinde.
                        </p>

                        <p>
                            Der das Gebäude umgebende Park, mit Bäumen, Wegen
                            und Blumen, stimmt den Besucher auf ein geistiges
                            Erlebnis ein, der Besucher kommt zur Ruhe.
                        </p>

                        <p>
                            Der Park unterstreicht die Architektur des Gebäudes
                            mit neun, gleichbedeutenden Eingängen. Diese
                            Eingänge werden symbolisch als eine Einladung an
                            alle Menschen jedweder Glaubensrichtung betrachtet.
                        </p>

                        <p>
                            Die das Haus der Andacht umgebende Parkanlage wird
                            nach ökologischen Richtlinien bewirtschaftet, Gifte
                            oder künstlicher Dünger werden von den Gärtnern
                            nicht eingesetzt. Damit Vögel, Bienen, Fledermäuse
                            und Hornissen auf dem Gelände nisten können wurden
                            eine große Anzahl von Nistkästen aufgehängt.
                            Insbesondere im Frühling herrscht reges Treiben der
                            Vögel und Insekten umlagern die Lavendelstauden um
                            das Haus der Andacht.
                        </p>

                        <p>
                            <div className="img-holder">
                                <img
                                    src={
                                        data.collage2Image.childImageSharp.fluid
                                            .src
                                    }
                                    alt=""
                                />
                            </div>
                        </p>

                        <p>
                            Aber auch außerhalb fester Veranstaltungen bietet
                            das Haus der Andacht einen Ort, um aufzutanken. Dort
                            gibt es die Gelegenheit zum persönlichen Gebet.
                            Jeder, der möchte kann diese „Oase für tiefes
                            Nachdenken” zum Meditieren und Beten nutzen.
                            Zusätzlich gibt es am Haus der Andacht das
                            Besucherzentrum, in dem sowohl Gruppen als auch
                            Einzelne herzlich willkommen sind. Dort haben Sie
                            die Möglichkeit Informationen über die Bahá’í
                            Religion zu erhalten und Gespräche über die Gemeinde
                            und das Haus der Andacht zu führen.
                        </p>

                        <p>
                            <div className="video-wrapper">
                                <iframe
                                    title="Haus der Andacht"
                                    src="https://www.youtube.com/embed/rYv8w6oxsMY?autoplay=1&loop=1"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; loop"
                                    allowfullscreen={true}
                                />
                            </div>
                        </p>

                        <p>
                            Es gibt auch die Möglichkeit, an Programmen für
                            Besuchergruppen teilzunehmen, um das Haus der
                            Andacht, seine Geschichte und sein Wirken kennen zu
                            lernen.
                        </p>
                        <p>
                            Viele Besucher des Hauses der Andacht in Hofheim -
                            Langenhain beschreiben ihre Empfindungen wie folgt:
                            <br />
                            <br />
                            <em>„Hier empfinde ich tiefe Ruhe“.</em>
                            <br />
                            <em>„Ein Ort der Kraft.“</em>
                            <br />
                            <em>„Hingezogen sein zu etwas Höherem.“</em>
                            <br />
                            <em>„Hier finde ich wieder meine Mitte.“</em>
                        </p>
                        <p>
                            Der Bücherladen gibt die Möglichkeit, Primär- und
                            Sekundärliteratur über die Bahá‘í-Religion,
                            Kinderbücher, Musik, DVDs, Schmuck und vieles mehr
                            zu erwerben.
                        </p>
                    </div>
                    <p className="separator" />
                </div>
            </div>

            <div className="intro-block intro-block--no-border">
                <Img
                    backgroundColor="#f8f6f4"
                    fluid={data.panoramaImage.childImageSharp.fluid}
                />
            </div>
        </main>
    </Layout>
);

export default HausDerAndacht;

export const pageQuery = graphql`
    query {
        heroImage: file(relativePath: { eq: "HDA-20-cropped.jpg" }) {
            ...fluidImage
        }
        collage1Image: file(relativePath: { eq: "hda-collage-1.jpg" }) {
            ...fluidImage
        }
        collage2Image: file(relativePath: { eq: "hda-collage-2.jpg" }) {
            ...fluidImage
        }
        panoramaImage: file(relativePath: { eq: "DSC08443.jpg" }) {
            ...fluidImage
        }
    }
`;
